// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   itineraries: [],
//   filteredItineraries: [],
//   uniqueAirlines: [],
//   isFiltered: false,
// };

// const itinerarySlice = createSlice({
//   name: "filterItinerary",
//   initialState,
//   reducers: {
//     setItinerary: (state, action) => {
//       state.itineraries = action.payload;
//       state.filteredItineraries = action.payload;

//       const airlineInfoMap = new Map();

//       action.payload.forEach((item) => {
//         const departureAirport = item.legs[0].schedules[0].carrier.marketing;

//         const airlineInfo = item; // You can change this to extract the desired info

//         // Add the airline info if the departure airport hasn't been added yet
//         if (!airlineInfoMap.has(departureAirport)) {
//           airlineInfoMap.set(departureAirport, airlineInfo);
//         }
//       });

//       // Convert the Map values to an array for storing in state
//       state.uniqueAirlines = Array.from(airlineInfoMap.values());
//     },
//     filterItineraries: (state, action) => {
//       const {
//         minPrice,
//         selectedTimes,
//         selectedStops,
//         selectedOption,
//         selectedMarketingNames,
//       } = action.payload;

//       state.filteredItineraries = state.itineraries.filter((item) => {
//         try {
//           const dbTime = item.legs[0].schedules[0].departure.time.split("+")[0];

//           const timeMatch = selectedTimes.some((range) => {
//             return dbTime >= range.start && dbTime <= range.end;
//           });

//           const stops =
//             item.legs && item.legs[0] && Array.isArray(item.legs[0].schedules)
//               ? item.legs[0].schedules.length - 1
//               : 0;

//           const stopMatch =
//             selectedStops.length === 0 || selectedStops.includes(stops);

//           // console.log( stopMatch);

//           const isNonRefundable =
//             item.pricingInformation[0].fare.passengerInfoList[0].passengerInfo
//               .nonRefundable;

//           const optionMatch =
//             selectedOption === null ||
//             (selectedOption === "true" && isNonRefundable === true) ||
//             (selectedOption === "false" && isNonRefundable === false);

//           const marketingName = item.legs[0].schedules[0].carrier.marketing;

//           const marketingMatch =
//             selectedMarketingNames.length === 0 ||
//             selectedMarketingNames.includes(marketingName);

//           return (
//             timeMatch &&
//             stopMatch &&
//             optionMatch &&
//             marketingMatch &&
//             item.pricingInformation[0].fare.totalFare.totalPrice > minPrice
//           );
//         } catch (error) {
//           console.error("Error filtering itinerary:", error, item);
//           return false;
//         }
//       });

//       state.isFiltered = true;
//     },

//     resetFilter: (state) => {
//       state.filteredItineraries = state.itineraries;
//       state.isFiltered = false;
//     },
//   },
// });

// export const { setItinerary, filterItineraries, resetFilter } =
//   itinerarySlice.actions;
// export default itinerarySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  itineraries: [],
  filteredItineraries: [],
  isFiltered: false,
};

const itinerarySlice = createSlice({
  name: "filterItinerary",
  initialState,
  reducers: {
    setItinerary: (state, action) => {
      state.itineraries = action.payload;
      state.filteredItineraries = action.payload;
    },
    filterItineraries: (state, action) => {
      const {
        minPrice,
        selectedTimes,
        selectedStops,
        selectedOption,
        selectedAirlines,
      } = action.payload;

      state.filteredItineraries = state.itineraries.filter((item) => {
        try {
          // Extract the time portion from the datetime string
          const dbTime = item.legs[0].schedules[0].departure.time.split("+")[0];
          // console.log(item, "item");

          // Check if the time falls within any selected time range
          const timeMatch = selectedTimes.some((range) => {
            return dbTime >= range.start && dbTime <= range.end;
          });

          const stops =
            item.legs && item.legs[0] && Array.isArray(item.legs[0].schedules)
              ? item.legs[0].schedules.length - 1
              : 0;

          // const filteredAirlines = item.legs[0].schedules[0].operating
          // const filteredAirlines = selectedAirlines.some((airline) => {
          //   return item.legs[0].schedules[0].carrier.operating === airline;
          // });

          const filteredAirlines =
            selectedAirlines.length > 0 &&
            selectedAirlines.some((airline) => {
              return item.legs[0].schedules[0].carrier.operating === airline;
            });

          const stopMatch =
            selectedStops.length === 0 || selectedStops.includes(stops);

          const isNonRefundable = 0.0;
          item.pricingInformation[0].fare.passengerInfoList[0].passengerInfo
            .nonRefundable;

          const optionMatch =
            selectedOption === null ||
            (selectedOption === "true" && isNonRefundable === true) ||
            (selectedOption === "false" && isNonRefundable === false);

          // Return true if both time and price conditions are met
          if (selectedAirlines.length > 0) {
            return (
              timeMatch &&
              filteredAirlines &&
              stopMatch &&
              optionMatch &&
              item.pricingInformation[0].fare.totalFare.totalPrice >= minPrice
            );
          }
          return (
            timeMatch &&
            stopMatch &&
            optionMatch &&
            item.pricingInformation[0].fare.totalFare.totalPrice >= minPrice
          );
        } catch (error) {
          console.error("Error filtering itinerary:", error, item);
          return false; // Exclude items with errors from the results
        }
      });

      state.isFiltered = true;
    },

    resetFilter: (state) => {
      state.filteredItineraries = state.itineraries;
      state.isFiltered = false;
    },
  },
});

export const { setItinerary, filterItineraries, resetFilter } =
  itinerarySlice.actions;
export default itinerarySlice.reducer;
