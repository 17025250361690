// import React from "react";
// import Image from "next/image";
// import apple from "../../assets/loginPopup/apple.png";
// import facebook from "../../assets/loginPopup/facebook.png";
// import google from "../../assets/loginPopup/google.png";
// import { useDispatch } from "react-redux";
// import { login } from "@/redux/authSlice";
// import globalAxiosURL from "@/hooks/globalAxiosURL";
// import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
// import FacebookLogin from "@greatsumini/react-facebook-login";
// import axios from "axios";

// const SocialLogin = ({ setIsLoading }) => {
//   const axiosURL = globalAxiosURL();
//   const dispatch = useDispatch();

//   const googleLogin = useGoogleLogin({
//     onSuccess: async (tokenResponse) => {
//       const accessToken = tokenResponse.access_token;
//       setIsLoading(true);

//       try {
//         const res = await axios.get(
//           "https://www.googleapis.com/oauth2/v3/userinfo",
//           {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           }
//         );

//         const userData = {
//           email: res?.data.email,
//           name: res?.data.name,
//           photo: res?.data.picture,
//           isSocialLogin: true,
//         };

//         if (res.status === 200) {
//           const response = await axiosURL.post("/auth/signup", userData);
//           if (response?.data?.user) {
//             dispatch(
//               login({
//                 email: response.data.user.email,
//                 token: response.data.token,
//               })
//             );
//             setIsLoading(false);
//           }
//         }
//       } catch (error) {
//         setIsLoading(false);
//       }
//     },
//     onError: (error) => {
//       console.log("Login Failed:", error);
//       setIsLoading(false);
//     },
//   });

//   const handleFacebookLogin = async (response) => {
//     setIsLoading(true);
//     try {
//       const userData = {
//         isSocialLogin: true,
//         name: response?.name,
//         email: response?.email,
//         photo: response?.picture?.data?.url,
//       };

//       if (response) {
//         const response = await axiosURL.post("/auth/signup", userData);

//   const handleGoogleLogin = async () => {
//     setIsLoading(true);
//     try {
//       const result = await signInWithPopup(auth, googleProvider);
//       const user = result.user;
//       if (user) {
//         const response = await axiosURL.post("/auth/signup", {
//           isSocialLogin: true,
//           name: user?.displayName,
//           email: user?.email,
//           photo: user?.photoURL,
//         });

//         if (response?.data?.user) {
//           dispatch(
//             login({
//               email: response.data.user.email,
//               token: response.data.token,
//             })
//           );
//           setIsLoading(false);
//         } else {
//           setIsLoading(false);
//         }
//       }
//     } catch (error) {
//       setIsLoading(false);
//     }
//   };

//   const handleFacebookLogin = async () => {
//     setIsLoading(true);
//     try {
//       const result = await signInWithPopup(auth, facebookProvider);
//       const user = result.user;
//       if (user) {
//         const response = await axiosURL.post("/auth/signup", {
//           isSocialLogin: true,
//           name: user?.displayName,
//           email: user?.email,
//           photo: user?.photoURL,
//         });
//         if (response?.data?.user) {
//           dispatch(
//             login({
//               email: response.data.user.email,
//               token: response.data.token,
//             })
//           );
//           setIsLoading(false);
//         }
//       }
//     } catch (error) {
//       setIsLoading(false);
//       console.log(error.code);
//       console.log(error.message);
//     }
//   };

//   const handleAppleLogin = async () => {
//     // const result = await signInWithPopup(auth, appleProvider);
//     // const user = result.user;
//     // console.log(user);
//   };

//   return (
//     <fieldset className="border-t mt-5">
//       <legend className="text-center text-[#575757] text-base px-2">
//         Or Continue with
//       </legend>
//       <div className="flex justify-evenly items-center pt-5">
//         <div className="cursor-pointer pt-1.5">
//           <FacebookLogin
//             appId="491802839872272"
//             onFail={(error) => {
//               console.log("Login Failed!", error);
//             }}
//             onProfileSuccess={handleFacebookLogin}
//           >
//             <Image src={facebook} alt="facebook" />
//           </FacebookLogin>
//         </div>
//         <div onClick={() => googleLogin()} className="cursor-pointer">
//           <Image src={google} alt="Google" />
//         </div>
//         <div onClick={handleAppleLogin} className="cursor-pointer">
//           <Image src={apple} alt="Apple" />
//         </div>
//       </div>
//     </fieldset>
//   );
// };

// export default SocialLogin ;

import React from "react";
import Image from "next/image";
import apple from "../../assets/loginPopup/apple.png";
import facebook from "../../assets/loginPopup/facebook.png";
import google from "../../assets/loginPopup/google.png";
import { useDispatch } from "react-redux";
import { login } from "@/redux/authSlice";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import axios from "axios";

const SocialLogin = ({ setIsLoading }) => {
  const axiosURL = globalAxiosURL();
  const dispatch = useDispatch();

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const accessToken = tokenResponse.access_token;
      setIsLoading(true);

      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const userData = {
          email: res?.data.email,
          name: res?.data.name,
          photo: res?.data.picture,
          isSocialLogin: true,
        };

        if (res.status === 200) {
          const response = await axiosURL.post("/auth/signup", userData);
          if (response?.data?.user) {
            dispatch(
              login({
                email: response.data.user.email,
                token: response.data.token,
              })
            );
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      setIsLoading(false);
    },
  });

  const handleFacebookLogin = async (response) => {
    setIsLoading(true);
    try {
      const userData = {
        isSocialLogin: true,
        name: response?.name,
        email: response?.email,
        photo: response?.picture?.data?.url,
      };

      if (response) {
        const apiResponse = await axiosURL.post("/auth/signup", userData);
        if (apiResponse?.data?.user) {
          dispatch(
            login({
              email: apiResponse.data.user.email,
              token: apiResponse.data.token,
            })
          );
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleAppleLogin = async () => {
    // Implement Apple login logic here
    // console.log("Apple login not yet implemented");
  };

  return (
    <fieldset className="border-t mt-5">
      <legend className="text-center text-[#575757] text-base px-2">
        Or Continue with
      </legend>
      <div className="flex justify-evenly items-center pt-5">
        <div className="cursor-pointer pt-1.5">
          {/* <FacebookLogin
            appId="491802839872272"
            onFail={(error) => {
              console.error("Login Failed!", error);
            }}
            onProfileSuccess={handleFacebookLogin}
          >
            <Image src={facebook} alt="Facebook" />
          </FacebookLogin> */}
        </div>
        <div onClick={() => googleLogin()} className="cursor-pointer mx-auto">
          <Image src={google} alt="Google" />
        </div>
        {/* <div onClick={handleAppleLogin} className="cursor-pointer">
          <Image src={apple} alt="Apple" />
        </div> */}
      </div>
    </fieldset>
  );
};

export default SocialLogin;
