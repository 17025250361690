import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  discountPrice: null,
};

const revalidateDataSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    setDiscountPrice: (state, action) => {
      state.discountPrice = action.payload;
    },
  },
});

export const { setDiscountPrice } = revalidateDataSlice.actions;

export default revalidateDataSlice.reducer;
